import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodySmall } from '@components/styled/Typography';
import { calculatePercentage, clamp } from '@utils/math';
import AssetBarLegendItem from '@entities/wallet/ui/ExchangeWalletAssetsBar/AssetBarLegendItem';
import AREAS_COLORS from '../../lib/constants';
import AssetBarItem from './AssetBarItem';
const getAssetBarBorderRadiusPosition = (index, assetsCount) => {
    if (assetsCount === 1) {
        return 'left-and-right';
    }
    if (index === 0) {
        return 'left';
    }
    if (index === assetsCount - 1) {
        return 'right';
    }
    return 'none';
};
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const AssetsBarContainer = styled.div(() => ({
    display: 'flex',
    width: '100%',
    height: 6,
}));
const ExchangeAssetsTooltips = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s * 5,
}));
const AssetsTooltipErrorText = styled(BodySmall)(props => ({
    color: props.theme.palette.error.main,
    lineHeight: '16px',
}));
const ProgressBarError = styled.div(props => ({
    width: '100%',
    height: 8,
    backgroundColor: props.theme.palette.error.main,
    borderRadius: 6,
}));
const IndividualAssetsToDisplayCount = 5;
const ExchangeWalletAssetsBar = ({ wallet, disabled = undefined, }) => {
    const { t } = useTranslation();
    const assetsToDisplay = useMemo(() => {
        // NOTE: without shallow-copy array.sort mutating the original array
        // which causes an error:
        // TypeError: Cannot assign to read only property '0' of object '[object Array]'
        const assets = [...wallet.assets];
        const assetsSortedByUSDTDesc = assets.sort((a, b) => b.amountUSDT - a.amountUSDT);
        const totalBalanceUSDT = assetsSortedByUSDTDesc
            .reduce((totalBalance, asset) => totalBalance + asset.amountUSDT, 0);
        const topAssets = [];
        assetsSortedByUSDTDesc
            .slice(0, IndividualAssetsToDisplayCount)
            .forEach((asset) => {
            // NOTE: should only display assets that have some balance on it
            if (asset.amountUSDT > 0) {
                topAssets.push({
                    assetName: asset.asset,
                    balanceUSDT: asset.amountUSDT,
                    percentage: calculatePercentage(asset.amountUSDT, totalBalanceUSDT),
                });
            }
        });
        const topAssetsBalanceUSDT = topAssets
            .reduce((totalBalance, assetData) => totalBalance + assetData.balanceUSDT, 0);
        const otherAssetsBalanceUSDT = totalBalanceUSDT - topAssetsBalanceUSDT;
        if (otherAssetsBalanceUSDT > 0) {
            topAssets.push({
                assetName: t('overview.exchange_wallets.card.others'),
                balanceUSDT: otherAssetsBalanceUSDT,
                percentage: calculatePercentage(otherAssetsBalanceUSDT, totalBalanceUSDT),
                displayApproximateSign: true,
            });
        }
        return topAssets;
    }, [
        wallet,
        t,
    ]);
    return (_jsxs(Container, { style: {
            opacity: disabled ? 0.5 : 1,
        }, children: [_jsxs(AssetsBarContainer, { children: [!assetsToDisplay.length && (_jsx(ProgressBarError, {})), assetsToDisplay.map((asset, index) => (_jsx(AssetBarItem, { color: AREAS_COLORS[index], width: clamp(asset.percentage, 1, 100), borderRadiusPosition: getAssetBarBorderRadiusPosition(index, assetsToDisplay.length) }, asset.assetName)))] }), _jsxs(ExchangeAssetsTooltips, { children: [!assetsToDisplay.length && (_jsx(AssetsTooltipErrorText, { children: t('overview.exchange_wallets.card.balance_lack') })), assetsToDisplay.map((asset, index) => (_jsx(AssetBarLegendItem, { assetBarData: asset, color: AREAS_COLORS[index] }, asset.assetName)))] })] }));
};
export default ExchangeWalletAssetsBar;
